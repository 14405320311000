import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Header from "../components/header";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Página no encontrada" />
    <Header />
    <main
      style={{
        width: "80vw",
        textAlign: "center",
        display: "initial",
        padding: 20,
      }}
    >
      <h1>¡Vaya! No hemos encontrado la página que estabas buscando</h1>
      <br />
      <p>Prueba a navegar por el menú para encontrar lo que buscas</p>
    </main>
  </Layout>
);

export default NotFoundPage;
